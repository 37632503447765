<template>
  <div class="session-header">
      <p class="zh">{{ title }}</p>
      <p class="en">{{en}}</p>
  </div>
</template>

<script>
export default {
    name: "session-header",
    props: {
        title: {
            type: String,
            default: ''
        },
        en:{
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="less">
.session-header {
    padding: 30Px 0;
    p {
        margin: 0;
    }
    .zh {
        font-size: 25Px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #2A2A31;
        letter-spacing: 10Px;
        text-align: center;
    }
    .en {

        font-size: 16Px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8F8C8C;
        letter-spacing:4Px;
        text-align: center;
    }
}
</style>