<template>
  <div class="hall">
      <hall-intr :hallInfo="hallInfo"></hall-intr>
      <video-into :hallInfo="hallInfo"></video-into>
      <session-header title="课程展示" en="COURSE DETAILS"></session-header>
      <hall-course-2 v-for="item in hallInfo.demoList" :key="item.Grade" :model="item"  title="演示课程"></hall-course-2>
      <hall-course v-for="(item,index) in hallInfo.lessonList" :bgColor="index%2==0?'#EDF0F5':'#ffffff'" :key="item.Grade" :model="item" @moreLesson="moreLesson" :title="item.Grade"></hall-course>
  </div>
</template>

<script>
import HallCourse2 from '../../components/website/hall/HallCourse2.vue'
import HallCourse from '../../components/website/hall/HallCourse.vue'
import HallIntr from '../../components/website/hall/HallIntr.vue'
import VideoInto from '../../components/website/hall/VideoInto.vue'
import SessionHeader from '../../components/website/sessions/SessionHeader.vue'
import { getHallById } from "@/api/website"
export default {
  components: { HallIntr, VideoInto, SessionHeader, HallCourse2, HallCourse },
  data() {
    return {
      hallInfo:{
        HallDesc:'',
        HallFeatures:'',
        HallMovie:'',
        HallMovieCover:'',
        HallName:'',
        lessonList:[],
        demoList:[]
      }
    }
  },
  mounted(){
    this.requestInfo()
  },
  methods:{
    requestInfo(){
      getHallById({hallId:this.$route.query.hallId}).then(res=>{
        console.log(res)
        if(!res.data.hallInfo.demoList){
          res.data.hallInfo.demoList = []
        }
        if(!res.data.hallInfo.lessonList){
          res.data.hallInfo.lessonList = []
        }
        this.hallInfo = res.data.hallInfo
      })
    },
    moreLesson(params){
      params.item.LessonList = params.item.LessonList.concat(params.list)
    }
  }
}
</script>

<style lang="less">
.hall {
    background: white;
    padding-bottom: 80Px;
}
</style>