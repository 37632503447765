<template>
  <div class="video-intr">
    <div class="video-intr-content">
      <div class="content-left">
        <p class="title">{{hallInfo.HallName}}视频介绍</p>
        <ul class="points">
          <li class="point-item" v-for="item in hallInfo.HallFeatures" :key="item">{{item}}</li>
        </ul>
      </div>
      <div class="img-warp">
        <img src="@/assets/hall1.png" alt="" srcset="" />
      </div>
      <div class="video">
        <video class="vdo" controls :src="hallInfo.HallMovie"></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "video-intr",
  props: {
    hallInfo: {
      type: Object,
      default: () => {
        return {
          HallDesc: "",
          HallFeatures: "",
          HallMovie: "",
          HallMovieCover: "",
          HallName: "",
          lessonList: [],
        };
      },
    },
  },
};
</script>

<style lang="less">
.video-intr {
    height: 568Px;
    background: #DDE9FF;
    .video-intr-content {
        height: 568Px;
        width: 1190Px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .content-left {
            padding-top: 94Px;
            .title {
                font-size: 34Px;
                margin: 0;
            }
            .points {
                padding: 0;
                padding-top: 20Px;
                .point-item {
                    font-size: 16Px;
                    padding-left: 10Px;
                    padding: 15Px 0;
                }
                .point-item::before {
                    display: inline-block;
                    content: "";
                    width: 16Px;
                    height: 16Px;
                    background: white;
                    margin-right: 10Px;
                }
            }
        }
        .img-warp {
            padding-top: 37Px;
            width: 433Px;
            height: 473Px;
            img {
                width: 453Px;
            }
        }
        .video {
            margin-top: 120Px;
            width: 500Px;
            height: 300Px;
            border-radius: 10Px;
            .vdo {
              width: 500Px;
              height: 300Px;
            }
        }
    }
}
</style>