<template>
  <div class="hall-course-item" @click="viewDetail">
      <img class="poster" :src="$attrs.model.FrontCover" alt="" srcset="">
      <div class="info-warp">
          <p class="course-item-title">{{$attrs.model.LessonName}}</p>
          <p class="course-desc">{{$attrs.model.LessonDesc}}</p>
          <div class="btm">
             <p class="teacher">研发团队：{{$attrs.model.Author}}</p>
             <ul class="datas">
                 <!-- <li class="data-item">
                     <img src="@/assets/dd.png" alt="" srcset="">
                     <span>2478</span>
                 </li>
                 <li class="data-item">
                     <img src="@/assets/cc.png" alt="" srcset="">
                     <span>124556</span>
                 </li> -->
             </ul>
          </div>
      </div>
  </div>
</template>

<script>
export default {
name:"hall-course-item",
methods: {
    viewDetail() {
      this.$emit("viewDetail",{lessonId:this.$attrs.model.LessonId});
    },
  },
}
</script>

<style lang="less">
.hall-course-item {
    width: 278pX;
    height: 294Px;
    background: white;
    border: 1px solid #EDF0F5;
    overflow: hidden;
    cursor: pointer;
    .poster {
        display: block;
        width: 280Px;
        height: 160Px;
        background: #3d3d33;
    }
    .info-warp {
        width: 280Px;
        height: 136Px;
        position: relative;
        .course-item-title {
            font-size: 16Px;
            color: #2A2A31;
            padding-top: 18Px;
            padding-left: 16Px;
            margin: 0;
        }
        .course-desc {
            font-size: 14Px;
            color: #2A2A31;
            padding: 10Px 16Px;
            margin: 0;
            max-height: 42px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .btm {
            position: absolute;
            left: 16Px;
            right: 16pX;
            bottom: 16Px;
            display: flex;
            justify-content: space-between;
            font-size: 12Px;
            color: #8E8E93;
            align-items: center;
            .teacher {
                width: 150Px;
                overflow: hidden;
                text-overflow:ellipsis;
                padding: 0;
                margin: 0;
                height: 18Px;
            }
            .datas {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
                .data-item {
                    display: flex;
                    margin-left: 8Px;
                    align-items: center;
                    img {
                        width: 10Px;
                        height: 10Px;
                    }
                    span {
                        padding-left: 4Px;
                        font-size: 12Px;
                    }
                }
            }
        }
    }
}
</style>