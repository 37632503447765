<template>
  <div class="hall-course" :style="{background:bgColor}">
      <a-spin :spinning="spinning">
      <img class="left-logo" v-if="bgColor=='#EDF0F5'" src="@/assets/right-logo.png" alt="" srcset="">
      <img class="right-logo" v-if="bgColor=='#EDF0F5'" src="@/assets/left-logo.png" alt="" srcset="">
      <div class="hall-course-content">
          <p class="course-title">{{ title }}</p>
          <div class="add-warp">
              <span @click="buyLesson">加入我的课程库</span>
          </div>
          <div class="course-list">
              <hall-course-item-2 v-for="item in model.LessonList" :key="item.Grade" :model="item" @viewDetail="viewDetail" ></hall-course-item-2>
          </div>
          <!-- <div class="view-more-warp">
              <img src="@/assets/viewmore.png" alt="" srcset="">
          </div> -->
      </div>
      </a-spin>
  </div>
</template>

<script>

import HallCourseItem2 from './HallCourseItem2.vue'
import { buyLesson } from "@/api/website"
export default {
  components: { HallCourseItem2},
    name: "hall-course",
    props: {
        bgColor:{
            type:String,
            default: '#EDF0F5'
        },
        title:{
            type:String,
            default: ''
        },
        model: {
            type: Object,
            default:()=>{
                return {
                    Grade:'',
                    LessonList:[]
                }
            }
        }
    },
    data() {
        return {
            spinning: false
        }
    },
    mounted(){
        this.$message.config({
            top: '100px'
        })
    },
    methods:{
        viewDetail({lessonId}){
            this.$router.push({name:'hallDetail',query:{lessonId:lessonId, hallId:this.$route.query.hallId}})
        },
        buyLesson(){
            this.spinning = true
            let lessonId = this.model.LessonList[0].LessonId
            buyLesson({hallId:this.$route.query.hallId, lessonId:lessonId}).then(res=>{
                this.spinning = false
                if(res.code == 200) {
                    this.$message.success(res.msg)
                }
            }).catch(err=>{
                if(err) {
                    this.spinning = false
                }
            })
        }
    }
}
</script>

<style lang="less">
.hall-course {
    min-height: 460Px;
    background: #EDF0F5;
    position: relative;
    .left-logo {
        position: absolute;
        left: 0;
        top: 56Px;
        width: 167Px;
    }
    .right-logo {
        position: absolute;
        right: 0;
        top: 56Px;
        width: 167Px;
    }
    .hall-course-content {
        position: relative;
        z-index: 100;
        width: 830Px;
        margin: 0 auto;
        .course-title {
            margin: 0;
            font-size: 24Px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 33Px;
            color: #2A2A31;
            text-align: center;
            padding-top: 60Px;
            letter-spacing: 10Px;
        }
        .add-warp {
            text-align: right;
            span {
                font-size: 15Px;
                font-family: SourceHanSansCN-Regular;
                color: @primary-color;
                cursor: pointer;
            }
            padding: 20Px 0;
        }
        .course-list {
            display: flex;
            justify-content: center;
            .item {
                margin-right: 30Px;
                margin-bottom: 30Px;
            }
            .item:nth-child(2n) {
                margin-right: 0;
            }
        }
        .view-more-warp {
            text-align: center;
            padding-top: 20Px;
            img {
                cursor: pointer;
            }
        }
    }
}
</style>