<template>
  <div class="hall-intr">
    <div class="hall-content">
      <session-header :title="hallInfo.HallName" :en="enMap[hallInfo.HallName]"></session-header>
      <div class="hall-intr-warp">
        <div class="img-warp">
          <img src="@/assets/hall2.png" alt="" srcset="" />
        </div>
        <div class="expo-right">
          <div class="expo-title">
            <p class="e-title">展馆简介/CURRICULUM EXPO</p>
            <p class="shadow">CURRICULUM EXPO</p>
          </div>
          <p class="info">
            {{hallInfo.HallDesc}}
          </p>
          <!-- <img src="@/assets/bookbtn.png" alt="" srcset="" /> -->
          <a-button class="book-btn" @click="closeAction" style="background:#801212;" type="primary">学科名片代言卡</a-button>
        </div>
      </div>
    </div>
    <a-modal :visible="visible" title="学科名片代言卡" width="80%" :footer="null" @cancel="closeAction">
      <img :src="cardMap[hallInfo.HallName]" style="width:100%;" alt="" srcset="">
    </a-modal>
  </div>
</template>

<script>
import SessionHeader from "../sessions/SessionHeader.vue";
import bk from '@/assets/dyk/bandui.jpg'
import chinese from '@/assets/dyk/chinese.jpg'
import daode from '@/assets/dyk/daode.jpg'
import english from '@/assets/dyk/english.jpg'
import kexue from '@/assets/dyk/kexue.jpg'
import math from '@/assets/dyk/math.jpg'
import meishu from '@/assets/dyk/meishu.jpg'
import tiyu from '@/assets/dyk/tiyu.jpg'
import yinyue from '@/assets/dyk/yinyue.jpg'
import zhonghe from '@/assets/dyk/zhonghe.jpg'
const enMap = Object.freeze({
  "体育馆":"Sports Hall",
        "音乐馆": "The Music Hall",
        "美术馆": "The Art Gallery",
        "科学馆": "Science Museum",
        "班队馆": "Class Team Pavilion",
        "语文馆": "The Chinese Pavilion",
        "道德与法治馆": "Morality And The Rule Of Law",
        "英语馆": "The English Pavilion",
        "数学馆": "The Mathematics Pavilion",
        "综合实践活动馆": "Comprehensive Practice Hall"
})
export default {
  name: "hall-intr",
  components: { SessionHeader },
  props: {
    hallInfo: {
      type: Object,
      default: () => {
        return {
          HallDesc: "",
          HallFeatures: "",
          HallMovie: "",
          HallMovieCover: "",
          HallName: "",
          lessonList: []
        };
      },
    },
  },
  data() {
    return {
      visible: false,
      cardMap:{
        "体育馆":tiyu,
        "音乐馆": yinyue,
        "美术馆": meishu,
        "科学馆": kexue,
        "班队馆": bk,
        "语文馆": chinese,
        "道德与法治馆": daode,
        "英语馆": english,
        "数学馆": math,
        "综合实践活动馆": zhonghe
      },
      enMap:enMap
    }
  },
  methods: {
    closeAction(){
      this.visible = !this.visible
    }
  }
};
</script>

<style lang="less">
.hall-intr {
    height: 800Px;
    background: #EDF0F5;
    .hall-content {
        height: 800Px;
        width: 1190Px;
        margin: 0 auto;
        .hall-intr-warp {
            display: flex;
            .img-warp {
                width: 500Px;
                height: 500Px;
                padding-top: 60Px;
                img {
                    width: 500Px;
                    height: 500Px;
                }
            }
            .expo-right {
                padding-top: 63Px;
                padding-left: 100Px;
                .expo-title {
                    position: relative;
                    .e-title {
                        font-size: 25Px;
                        font-weight: 800;
                        line-height: 23px;
                        color: #2A2A31;
                        letter-spacing: 2px;
                        padding-right: 40Px;
                        position: relative;
                        z-index: 100;
                    }
                    .e-title::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 30Px;
                        height: 4Px;
                        width: 114px;
                        background: #801212;
                    }
                    .shadow {
                        position: absolute;
                        top: -40Px;
                        right: 0;
                        font-size: 40Px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #D9D9D9;
                        opacity: 0.4;
                    }
                }
                .info {
                    padding-top: 30Px;
                    width: 500Px;
                    font-size: 14Px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 25Px;
                    color: #2A2A31;
                    margin-bottom: 40Px;
                    white-space: pre-wrap;
                }
                .book-btn {
                    cursor: pointer;
                    background: #801212;
                    outline: none;
                    border: none;
                }
            }
        }
    }
}
</style>