<template>
  <div class="hall-course" :style="{background:bgColor}">
      <a-spin :spinning="spinning">
      <img class="left-logo" v-if="bgColor=='#EDF0F5'" src="@/assets/right-logo.png" alt="" srcset="">
      <img class="right-logo" v-if="bgColor=='#EDF0F5'" src="@/assets/left-logo.png" alt="" srcset="">
      <div class="hall-course-content">
          <p class="course-title">{{ title }}</p>
          <div class="add-warp">
              <span @click="buyLesson">加入我的课程库</span>
          </div>
          <div class="vip-course-list">
              <hall-course-item v-for="item in model.LessonList" :key="item.Grade" :model="item" @viewDetail="viewDetail" ></hall-course-item>
          </div>
          <div class="view-more-warp" v-if="model.LessonList.length > 3 && showAll">
              <img src="@/assets/viewmore.png" alt="" srcset="" @click="showAllAction">
          </div>
      </div>
      </a-spin>
  </div>
</template>

<script>

import HallCourseItem from './HallCourseItem.vue'
import { copyUnit2MyUnitBat,getMoveLesson } from "@/api/website"
export default {
  components: {HallCourseItem},
    name: "hall-course",
    props: {
        bgColor:{
            type:String,
            default: '#EDF0F5'
        },
        title:{
            type:String,
            default: ''
        },
        model: {
            type: Object,
            default:()=>{
                return {
                    Grade:'',
                    LessonList:[]
                }
            }
        }
    },
    data() {
        return {
            spinning: false,
            showAll: true,
            page:1
        }
    },
    mounted(){
        this.$message.config({
            top: '100px'
        })
    },
    methods:{
        viewDetail({lessonId}){
            this.$router.push({name:'hallDetail',query:{lessonId:lessonId, hallId:this.$route.query.hallId}})
        },
        buyLesson(){
            this.spinning = true
            copyUnit2MyUnitBat({hallId:this.$route.query.hallId,grade:this.model.Grade}).then(res=>{
                this.spinning = false
                if(res.code == 200) {
                    this.$message.success(res.msg)
                }
            }).catch(err=>{
                if(err) {
                    this.spinning = false
                }
            })
        },
        showAllAction(){
            this.page += 1
            this.spinning = true
            getMoveLesson({hallId:this.$route.query.hallId,grade:this.model.Grade, page:this.page}).then(res=>{
                this.spinning = false
                if(res.code == 200) {
                    if(res.data.lessonList.length == 0) {
                        this.showAll = false
                        this.$message.success('没有更多课程了！')
                    } else {
                        this.$emit('moreLesson',{list:res.data.lessonList,item:this.model})
                    }
                }
            }).catch(err=>{
                if(err) {
                    this.spinning = false
                }
            })
            
        }
    }
}
</script>

<style lang="less">
.hall-course {
    min-height: 540Px;
    background: #EDF0F5;
    position: relative;
    .left-logo {
        position: absolute;
        left: 0;
        top: 56Px;
        width: 167Px;
    }
    .right-logo {
        position: absolute;
        right: 0;
        top: 56Px;
        width: 167Px;
    }
    .hall-course-content {
        position: relative;
        z-index: 100;
        width: 1170Px;
        margin: 0 auto;
        .course-title {
            margin: 0;
            font-size: 24Px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 33Px;
            color: #2A2A31;
            text-align: center;
            padding-top: 60Px;
            letter-spacing: 10Px;
        }
        .add-warp {
            text-align: right;
            span {
                font-size: 15Px;
                font-family: SourceHanSansCN-Regular;
                color: @primary-color;
                cursor: pointer;
            }
            padding: 20Px 0;
        }
        .vip-course-list {
            display: flex;
            flex-wrap: wrap;
            .hall-course-item {
                margin-right: 15Px;
                margin-bottom: 15Px;
            }
            .hall-course-item:nth-child(4n) {
                margin-right: 0;
            }
        }
        .view-more-warp {
            text-align: center;
            padding-top: 50Px;
            padding-bottom: 50Px;
            img {
                cursor: pointer;
            }
        }
    }
}
</style>